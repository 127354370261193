import React, { Component } from "react"
import { withGoogleReCaptcha } from "react-google-recaptcha-v3"
import axios from "axios"

class CaptchaForm extends Component {
  onSubmit(e) {
    const form = e.target
    e.preventDefault()
    if (this.props.formSent) {
      this.props.formSent()
    }
    this.fetchToken()
      .then(token => this.submitForm(form, token))
      .catch(response =>
        console.log(
          `There was an error fetching the ReCaptcha token ${response}`
        )
      )
    // the form can only be actually submitted after token was received and
    // set in form. This is why preventDefault is used above
    return false
  }

  submitForm(form, token) {
    // this function actually submits the form once fetchToken promise
    // is resolved (token is actually received from reCaptcha api)
    form.children["recaptcha-token"].value = token
    // canSubmit is a validation function from a child form (a component
    // where CaptchaForm is used)
    // console.log(token)

    const canSubmit = this.props.canSubmit
      ? this.props.canSubmit(form.children)
      : true
    if (canSubmit) {
      axios({
        method: "post",
        url: this.props.action,
        data: new FormData(form),
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(response => this.processResponse(response))
        .catch(response => this.processError(response))
    }
  }

  processResponse(response) {
    if (this.props.formPosted) {
      this.props.formPosted(response)
    }
  }

  processError(response) {}

  async fetchToken() {
    return await this.props.googleReCaptchaProps.executeRecaptcha(
      "contact_form"
    )
  }

  render() {
    return (
      <form
        action={this.props.action}
        method={this.props.method}
        onSubmit={e => {
          this.onSubmit(e)
        }}
      >
        <input hidden id="recaptcha-token" name="token"></input>
        {this.props.children}
      </form>
    )
  }
}

export default withGoogleReCaptcha(CaptchaForm)
