import React from "react"
import Layout from "../components/Layout"
import ContactForm from "../components/ContactForm"

const product = () => {
  return (
    <Layout
      title="Contact page"
      description="Please use this form to tell us your Data Quality story"
      contentType="website"
    >
      <ContactForm />
    </Layout>
  )
}

export default product
