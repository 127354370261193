import React, { Component } from "react"
import CaptchaForm from "./CaptchaForm"
import styles from "../css/ContactForm.module.css"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export default class ContactFormExample extends Component {
  state = {
    disabled: false,
    status: "",
  }

  validateInputs(children) {
    // console.log(children)
    return true
  }

  formSent = () => {
    this.setState({ disabled: true, status: "Sending your message" })
  }

  formPosted = response => {
    var message = ""
    if (response.status === 200) {
      message = "Thank you, your message has been sent"
    } else {
      if (response.data) {
        const data = response.data
        if (!data["captcha_status"].success) {
          message =
            "Sorry. Google's captcha suggests that you are a bot. Please navigate around the site and send this message again"
        } else if (!data["email_status"].success) {
          message =
            "Sorry, there was a failure while sending an email. Are you sure the email you provided is correct?"
        } else {
          message = "Sorry there was an error sending your message"
        }
      }
    }
    this.setState({ status: message })
  }

  render() {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_CAPTCHA_SITE_KEY}
      >
        <section className={styles.formContainer}>
          <CaptchaForm
            action={process.env.GATSBY_CONTACT_FORM_ENDPOINT}
            method="POST"
            canSubmit={this.validateInputs}
            formSent={this.formSent}
            formPosted={this.formPosted}
          >
            <h4>Contact us</h4>
            <label className={styles.label} htmlFor="name">
              Your name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={styles.input}
              // disabled inputs are not submitted. To overcome this, I use readonly. See https://stackoverflow.com/a/7357314
              readOnly={this.state.disabled}
            />
            <label className={styles.label} htmlFor="email">
              Your e-mail:
            </label>
            <input
              // type="email"
              pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
              id="email"
              name="email"
              size="50"
              readOnly={this.state.disabled}
              className={styles.input}
            />
            <label className={styles.label} htmlFor="body">
              Message:
            </label>
            <textarea
              rows="6"
              cols="50"
              id="body"
              name="body"
              readOnly={this.state.disabled}
              className={styles.input}
            ></textarea>
            <label hidden={!this.state.disabled} className={styles.label}>
              {this.state.status}
            </label>
            <input
              type="submit"
              id="contact-submit"
              value="Send"
              disabled={this.state.disabled}
              hidden={this.state.disabled}
              className={styles.submit}
            />
          </CaptchaForm>
        </section>
      </GoogleReCaptchaProvider>
    )
  }
}
